@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-main {
	background-color: #D72128;
}

.react-datepicker-wrapper {
    width: 100%;
}
